import React, { useCallback, useEffect, useState, type FunctionComponent } from 'react';
import Modal from "react-modal";
import { AbAskBubble } from './components/AbAskBubble';
import { AbWidgetModal } from './components/AbWidgetModal';
import { EVENT_TYPE_ENUM, hideWidgetEvent, showWidgetEvent, type WidgetType } from './types';

// We want to import index.css in the bundle only for widget SDK
// Importing css file for react-component will break the library.
if (process.env.REACT_APP_SDK) {
  require('./index.css');
}

export const App: FunctionComponent = (): JSX.Element | null => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [docId, setDocId] = useState<string>();
  const [blockId, setBlockId] = useState<string>();
  const [jwt, setJwt] = useState<string | undefined>();
  const [shareableToken, setShareableToken] = useState<string | undefined>();
  const [initialScreen, setInitialScreen] = useState<WidgetType | undefined>();

  const widgetActionListener = useCallback(() => {
    setIsModalOpen(window._archbee.widget.isOpen)
    setDocId(window._archbee.widget.docId)
    setBlockId(window._archbee.widget.blockId)
    setJwt(window._archbee.widget.jwt)
    setShareableToken(window._archbee.widget.shareableToken)
    setInitialScreen(window._archbee.widget.widgetType)

    const widget = document.getElementsByClassName("ab-widget")
    if (!widget.length) return;

    if (window._archbee.widget.isOpen) {
      widget[0].removeAttribute("style")
    } else {
      widget[0].setAttribute("style", "visibility: hidden")
    }
  }, [])

  const onPostMessageReceived = useCallback(({ data }: MessageEvent) => {
    if (data.source !== 'archbee-widget') {
      return;
    }

    switch (data.type) {
      case 'ab-doc-close':
        closeModal()
        break;
      default:
        break;
    }
  }, [])

  useEffect(() => {
    window.addEventListener(EVENT_TYPE_ENUM.showWidget, widgetActionListener);
    window.addEventListener(EVENT_TYPE_ENUM.hideWidget, widgetActionListener);
    window.addEventListener('message', onPostMessageReceived)

    return () => {
      window.removeEventListener(EVENT_TYPE_ENUM.showWidget, widgetActionListener);
      window.removeEventListener(EVENT_TYPE_ENUM.hideWidget, widgetActionListener);
      window.removeEventListener('message', onPostMessageReceived);
    }
  }, [widgetActionListener, onPostMessageReceived]);

  const closeModal = (): void => {
    window._archbee.widget.isOpen = false;
    window._archbee.widget.docId = undefined;
    window._archbee.widget.blockId = undefined;
    window.dispatchEvent(hideWidgetEvent() as Event);
  }

  const openAskBubbleModal = (): void => {
    window._archbee.widget.isOpen = true;
    window._archbee.widget.widgetType = 'search';
    window.dispatchEvent(showWidgetEvent() as Event);
  }

  useEffect(() => {
    Modal.setAppElement("#archbee-docs-widget-modal")
  }, []);

  return (
    <>
      {window._archbee.widget.bubble === 'ask' && (
        <AbAskBubble
          isModalOpen={isModalOpen}
          openAskBubbleModal={openAskBubbleModal}
        />
      )}

      <div
        id="archbee-docs-widget-modal">
        {isModalOpen && (
          <AbWidgetModal
            docId={docId || ''}
            blockId={blockId || ''}
            spaceId={window._archbee.widget.spaceId || window._archbee.widget.collectionId || ''}
            jwt={jwt}
            shareableToken={shareableToken}
            screen={initialScreen}
            onClose={closeModal}
          />
        )}
      </div>
    </>
  );
};

export default App;
