declare global {
  interface Window {
    _archbee: ArchbeeWidgetInstance;
  }
}

export interface ArchbeeWidgetInstance {
  version: string;
  widget: AbWidgetData;
  queue: any;
  push: Function;
}

export enum EVENT_TYPE_ENUM {
  init = "init",
  showWidget = "show-widget",
  hideWidget = "hide-widget",
}

type WidgetEventType =
  | EVENT_TYPE_ENUM.init
  | EVENT_TYPE_ENUM.showWidget
  | EVENT_TYPE_ENUM.hideWidget;

export const EVENT_TYPE_LIST = [
  EVENT_TYPE_ENUM.init,
  EVENT_TYPE_ENUM.showWidget,
  EVENT_TYPE_ENUM.hideWidget,
];

export interface AbWidgetData {
  eventType: WidgetEventType;
  collectionId?: string;
  spaceId?: string;
  docId?: string;
  blockId?: string;
  isOpen: boolean;
  selector: string;
  origin?: string;
  onRenderFinish?: Function;
  hasExecuted: boolean;
  jwt?: string;
  shareableToken?: string;
  widgetType?: WidgetType;
  bubble?: BubbleType
}

export type BubbleType = 'ask' | 'invisible'
export type WidgetType = 'docs' | 'search'
export type LoadingStrategy = 'lazy' | 'eager'

export interface AbWidgetRef {
  open: () => void
  close: () => void
  instance: () => ArchbeeWidgetInstance
}

export const showWidgetEvent = (): CustomEvent<AbWidgetData> | undefined => {
  if (typeof window === 'undefined') return;

  return new CustomEvent(EVENT_TYPE_ENUM.showWidget)
};
export const hideWidgetEvent = (): CustomEvent<AbWidgetData> | undefined => {
  if (typeof window === 'undefined') return;

  return new CustomEvent(EVENT_TYPE_ENUM.hideWidget)
};
